<script>

    import { onMount } from 'svelte';

    //import { config, appstate, markerSize, scolorscaler, embeddings } from '../stores/state.js';

    //import DataFrame from 'dataframe-js';
    import { Stage, Layer, Group, Rect, Line, Text, Label, Tag } from 'svelte-konva';

    import chroma from 'chroma-js';


    import { getContext } from 'svelte';

    const context = getContext('app');
    let { appId, eventbus, controller } = context.app();

    let metadata = controller.getMetadata();


    import getStores from '@/utils/store';
    const { variantDensity } = getStores();


    import ChromosomeHeatmap from '@/components/canvas/ChromosomeHeatmap.svelte';
    import AxisTicks from '@/components/canvas/AxisTicks.svelte';

    import LoadingAnimation from '@/components/utils/LoadingAnimation.svelte';
    let showLoadingAnimation = false;


    let colorscaler;

    let chromosomes = [];
    let dataLoaded = false;

    let darkred = chroma('red').darken(1.5).saturate(3);
    //let darkred = chroma('black');
    let colorscalerLegend = chroma.scale(['white', darkred ]).mode('lab').colors(100);

    let minAll, maxAll;
    let valuesLengthsMax;
    let canvasContainer;

    let barHeight = 30;
    let barGroupHeight = 45;
    let marginLeft = 100;

    let x = 100;
    let _x = 100;
    let y = 100;

    let stageHeight = 580;

    let chromosomesVisible = {};

    let genomePosLabelLayer;
    let genomePosLabel;
    let genomePosText;
    let stage;

    let ticks;


    let options = [
        { id: 'variant_density', label: 'Variant density', unit: '1/bp' },
        { id: 'rare_variant_density', label: 'Rare variant density', unit: '1/bp' },
        { id: 'genes_density', label: 'Gene density', unit: '1/bp' },
        //{ id: 'gene_transposon_density', label: 'Transposon-related genes density', unit: '1/bp' },
        { id: 'missing_calls_density', label: 'Missing calls density', unit: '1/bp' },
        { id: 'heterozygous_calls_density', label: 'Heterozygous calls density', unit: '1/bp' },
        { id: 'ld_mean', label: 'Linkage Disequilibrium r²', unit: 'r²' },
        { id: 'pi', label: 'Nucleotide Diversity π', unit: 'π' },
        { id: 'tajima_d', label: 'Tajima`s D', unit: 'D' },
        { id: 'watterson_theta', label: 'Watterson estimator θ', unit: 'θ' },
        //{ id: 'fst_hudson', label: 'Fixation Index (FST)', unit: '-' },
        { id: 'mean_read_depth', label: 'Read Depth', unit: '-' }, // read_depth
    ];

    let fstDatasets = [
        //'2-row vs 6-row',
        //'Elite vs Landrace',
        //'Nepal vs France',
        'Nepal vs France (downsampled)',
        //'Landrace 2-rowed vs 6-rowed',
        'Landrace 2-rowed vs 6-rowed (downsampled)',
        'Germany Spring vs Winter (downsampled)'
    ];
    let selectedFstDataset = fstDatasets[0];


    let selectedOption = options[0];


    function handleOnChangeChromosomeVisibility() {
        //alert('test');
        prepare();
    }

    let data;
    let subgroups = [];
    let selectedSubgroupId;

    async function loadAvailableSubgroups() {
        const url = controller.config.apiBaseUrl+'/windowed_summary_statistics_subgroups';
        const response = await fetch(url);
        subgroups = await response.json();
        selectedSubgroupId = subgroups[0];
    }

    async function loadDataAll() {
        showLoadingAnimation = true;
        //const url = controller.config.apiBaseUrl+'/windowed_summary_statistics';
        const url = controller.config.apiBaseUrl+'/stats/windowed';
        let response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({
                subgroup_id: selectedSubgroupId,
                fst_dataset_id: selectedFstDataset,
            })
        });
        data = await response.json();
        showLoadingAnimation = false;
        prepare();
    }


    function initCheckboxStates(_chromosomes) {
        if ( Object.entries(chromosomesVisible).length > 0 ) {
            return;
        }
        for (const [chrom, values] of Object.entries(_chromosomes)) {
            chromosomesVisible[chrom] = true;
        }
    }

    function prepare() {

        chromosomes = [];

        let numberOfChromosomes = metadata.chromosomes.length;
        stageHeight = (numberOfChromosomes * barGroupHeight) + 80 + 20;

        //let _chromosomes = $variantDensity[selectedOption.id];
        let allValues = [];
        let valuesLengths = [];

        for (const [chrom, stats] of Object.entries(data)) {

            if (!stats[selectedOption.id]) {
                continue;
            }

            let values = stats[selectedOption.id];
            values = values.map(value => {
                if (value === null) {
                    return 0;
                } else {
                    return value;
                }
            })
            valuesLengths.push(values.length);

            initCheckboxStates(data);

            if (chromosomesVisible[chrom] === true) {
                allValues = [...allValues, ...values];
            }

            let min = Math.min( ...values ),
                max = Math.max( ...values );
            
            let _colorscaler = chroma.scale(['white', darkred ]).domain([min, max]).mode('lab');

            let newItem = {
                chrom: chrom,
                colorscaler: _colorscaler,
                values: values,
                min: min,
                max: max,
                ticks: createTicks(values.length, 0, metadata.chromosomesById[chrom].end, 100000000)
            };
            chromosomes = [...chromosomes, newItem];
        }

        let endMax = Math.max(...metadata.chromosomes.map(x => x.end));
        valuesLengthsMax = Math.max(...valuesLengths);
        ticks = createTicks(valuesLengthsMax, 0, endMax, 100000000);

        minAll = Math.min( ...allValues );
        maxAll = Math.max( ...allValues );

        colorscaler = chroma.scale(['white', darkred ]).domain([minAll, maxAll]).mode('lab');
        colorscalerLegend = chroma.scale(['white', darkred ]).mode('lab').colors(100);

        if (selectedOption.id === 'tajima_d') {
            colorscaler = chroma.scale([darkred, 'white', 'blue']).domain([minAll, 0, maxAll]).mode('lab');
            colorscalerLegend = chroma.scale([darkred, 'white', 'blue']).mode('lab').colors(100);
        }

        //colorscaler = chroma.scale('Viridis').domain([minAll, maxAll]).mode('lab');
    }


    function createTicks(lengthPixels, start, end, stepsize = null) {
        let ticks = [];

        //let binSize = stepsize;
        let intervals = end / stepsize;
        let binSizePixels = lengthPixels / intervals;

        for (let i = 0; i <= intervals; i++) {
            ticks.push({
                label: (start + (i * stepsize)).toLocaleString(),
                pos: Math.ceil((binSizePixels * i) - 1)
            });
        }

        return ticks;
    }


    function getTextConfig(x, y, text, size = 15, args = {}) {

        let config = {
            x: x,
            y: y,
            text: text,
            fontSize: size,
            fontFamily: 'Arial',
            fill: 'black'
        }

        return {...config, ...args}
    }

    function roundedFloat(num) {
        return num.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 3
        })
    }


    let configScaleLegend = {
        x: 0.5,
        y: 0.5,
        width: 300,
        height: 20,
        fillLinearGradientStartPoint: { x: 0, y: 0 },
        fillLinearGradientEndPoint: { x: 300, y: 0 },
        fillLinearGradientColorStops: [0, 'white', 1, darkred.hex('rgb')],
        stroke: 'black',
        strokeWidth: 1,
        draggable: false,
    }

    let strokeColor = 'black';

    function getCentromereSymbolConfig() {

        let config = {
            x: 0,
            y: 0,
            points: [0, 0, 5, 5, 10, 0],
            closed: true,
            stroke: 'black',
            strokeWidth: 2,
            fill: 'black',
        }
        return config;
    }

    function getCentromereSymbolGroupConfig(chrom) {
        let centromerePosition = Math.ceil(metadata.chromosomesById[chrom].centromere_position / 500000);

        let opacity = 0;
        if (centromerePosition > 0) {
            opacity = 1;
        }

        let config = {
            x: centromerePosition + 0.5 + 20,
            y: 1.5,
            width: 20,
            height: 20,
            opacity: opacity
        }
        return config;
    }

    function handleOnClickChrom(evt) {
        let clickPosX = evt.detail.target.getRelativePointerPosition()['x'] - 0.5;
        let pos = clickPosX * 500000;
        console.warn(pos);
        controller.goToChromosomeAndPosition(evt.detail.target.attrs.id, pos);
    }

    function handleOnMouseMoveChrom(evt) {
        let cursorPosX = evt.detail.target.getRelativePointerPosition()['x'] - 0.5;
        let genomePos = cursorPosX * 500000;
        let mousePos = stage.getPointerPosition();

        genomePosLabel.show();
        genomePosText.text(genomePos.toLocaleString() + ' bp');
        genomePosLabel.position({
            x: mousePos.x + 10,
            y: mousePos.y + 10,
        });
        //genomePosLabel.moveToTop();
        genomePosLabelLayer.moveToTop();
    }

    function onClickChromLabel(chrom) {
        eventbus.emit('showMainComponent', {
            component: 'ChromosomeSummaryDetails',
            chrom: chrom
        });
    }

    function handleOnChangeStatistic() {
        prepare();
    }

    onMount(() => {
        let _variantDensity = $variantDensity[selectedOption.id];
        //console.log( Object.entries(_variantDensity) );

        //if (Object.entries(_variantDensity).length > 0) {
        if (_variantDensity) {
            prepare();
        } else {
            loadDataAll();
            //loadAvailableSubgroups();
        }
    });

    function downloadURI(uri, name) {
        let link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function onClickDownloadImage() {
        let dataURL = stage.toDataURL({ pixelRatio: 3 });
        downloadURI(dataURL, 'stats.png');
    }

</script>
    
<div bind:this={canvasContainer} style="position: relative; border:0px solid black; height: 580px; overflow-y: scroll;">

    <!--<div style="padding: 20px 10px 10px 60px;">-->
        <!--<button class="divbrowse-btn divbrowse-btn-light ml" on:click={onClickDownloadImage}>Download as Figure</button>-->
        <!--<input bind:value={tickLabelsFontSize} on:change={onChangeTickLabelsFontSize} type="range" min="10" max="16" /> {tickLabelsFontSize}-->
    <!--</div>-->

    <!--
    <div style="padding: 10px 50px; font-size: 85%; display: flow-root;">
        <table class="info" style="float: left; border: 1px solid rgb(150,150,150);">
            <tr>
                <td class="title">Organism:</td>
                <td>Barley</td>
            </tr>
            <tr>
                <td class="title">Number of genotypes:</td>
                <td>1316</td>
            </tr>
            <tr>
                <td class="title">Number of variants:</td>
                <td>127123123</td>
            </tr>
        </table>

        <table class="info" style="float: left; border: 1px solid rgb(150,150,150);margin-left: 20px;">
            <tr>
                <td class="title">Reference genome:</td>
                <td><a href="#">Morex V3</a></td>
            </tr>
            <tr>
                <td class="title">Genome annotation:</td>
                <td><a href="#">Morex V3</a></td>
            </tr>
            <tr>
                <td class="title">Number of variants:</td>
                <td>127123123</td>
            </tr>
        </table>

    </div>
    -->

    <div style="display: flow-root; width: 1000px; margin: 16px 0 0 80px; border: 0px solid black;">

        <button on:click={onClickDownloadImage} class="divbrowse-btn divbrowse-btn-light" style="float: left;">Download as Figure</button>

        <select name="" bind:value={selectedOption} on:change|preventDefault={handleOnChangeStatistic} class="divbrowse-form-control" style="float:left; margin-left: 10px;/*position: absolute; top: 20px; left: 1000px;*/">
            {#each options as option}
            <option value="{option}">{option.label}</option>
            {/each}
        </select>

        {#if selectedOption.id !== 'fst_hudson'}
        <select name="" bind:value={selectedSubgroupId} on:change|preventDefault={() => loadDataAll()} class="divbrowse-form-control" style="float:left; margin-left: 10px;/*position: absolute; top: 20px; left: 1200px;*/">
            {#each subgroups as subgroupId}
            <option value="{subgroupId}">{subgroupId}</option>
            {/each}
        </select>
        {/if}

        {#if selectedOption.id === 'fst_hudson'}
        <select name="" bind:value={selectedFstDataset} on:change|preventDefault={() => loadDataAll()} class="divbrowse-form-control" style="float:left; /*position: absolute; top: 20px; left: 1200px;*/">
            {#each fstDatasets as fst}
            <option value="{fst}">{fst}</option>
            {/each}
        </select>
        {/if}

        {#if showLoadingAnimation}
        <div style="float: left; margin: 4px 0 0 10px;">
            <LoadingAnimation size="small" />
        </div>
        {/if}

    </div>

    <div style="position: absolute; top: 0; left: 0; z-index: 100;">
        {#if chromosomes.length > 0}

        {#each chromosomes as chrom, i}
        <input type="checkbox" name="test" style="position: absolute; top: {143+(i*barGroupHeight)}px; left: 15px;" 
            bind:checked={chromosomesVisible[chrom.chrom]}
            on:change|preventDefault={handleOnChangeChromosomeVisibility} />
        {/each}

        {#each chromosomes as chrom, i}
        <a href="#" on:click|preventDefault={() => onClickChromLabel(chrom.chrom) } style="position: absolute; top: {144+(i*barGroupHeight)}px; left: 45px;">{metadata.chromosomesById[chrom.chrom].label}</a>
        {/each}

        {/if}
    </div>

    <div style="/*height: 400px; overflow-y: scroll;*/ border: 0px solid black;">
    <Stage bind:handle={stage} config={{ width: 1600, height: stageHeight }}>

        {#if chromosomes.length > 0}
        <Layer config={{ x: 0, y: 0, width: 1600, height: 600 }}>

            <Rect config={{ x: 0, y: 0, width: 1600, height: 600, fill: 'rgb(255,255,255)' }} />

            <Group config={{ x: 40, y: 10, width: 1600, height: 600 }} >

                <Group config={{ x: 40, y: 80 }} >
                    <AxisTicks ticks={ticks} tickLabelsFontSize={10.5} showLabels={true} />
                    {#each ticks as tick, i}
                    <Line config={{ points: [tick.pos+0.5, -4, tick.pos+0.5, ((chromosomes.length*(barHeight*2))-barHeight*2)], stroke: 'black', strokeWidth: 1, dash: [2,2] }} />
                    {/each}
                </Group>

                {#each chromosomes as chrom, i}
                <Group config={{ x: 0, y: (i*barGroupHeight)+80, width: 1500, height: barGroupHeight }} >
                    {#if chrom.values}
                        <Group config={{ x: 40, y: 0, width: 1500, height: barGroupHeight }} >
                            {#if chromosomesVisible[chrom.chrom]}
                            <Group config={{ x: 0, y: 0, width: 1500, height: barGroupHeight }} >
                                <ChromosomeHeatmap values={chrom.values} barHeight={barHeight} colorscaler={colorscaler} label={''} />
                                <!--<AxisTicks ticks={chrom.ticks} tickLabelsFontSize={10.5} showLabels={true} />-->
                            </Group>

                            <Rect 
                                config={{ id: chrom.chrom, x: 0.5, y: 0.5, width: chrom.values.length, height: barHeight, stroke: strokeColor, strokeWidth: 0, listening: true }}
                                on:mouseenter={(evt) => { canvasContainer.style.cursor = 'pointer'; /*evt.detail.target.strokeWidth(2.5);*/ } }
                                on:mouseleave={(evt) => { canvasContainer.style.cursor = 'default'; /*evt.detail.target.strokeWidth(1);*/ genomePosLabel.hide(); } }
                                on:mousemove={(evt) => handleOnMouseMoveChrom(evt) } 
                                on:click={ (evt) => { handleOnClickChrom(evt) } } 
                            />
                            {:else}
                            <Rect config={{ id: chrom.chrom, x: 0.5, y: 0.5, width: chrom.values.length, height: barHeight, stroke: strokeColor, strokeWidth: 1 }} } />
                            {/if}

                            
                            <Text config={getTextConfig(valuesLengthsMax + 10, 3, 'Length: '+metadata.chromosomesById[chrom.chrom].end.toLocaleString()+' bp', 11 )} />
                            <Text config={getTextConfig(valuesLengthsMax + 10, 18, 'Variants: '+metadata.chromosomesById[chrom.chrom].number_of_variants.toLocaleString(), 11 )} />
                            

                            <Group config={getCentromereSymbolGroupConfig(chrom.chrom)}>
                                <Line config={getCentromereSymbolConfig()} />
                            </Group>
                        </Group>
                        
                        <!--<Text config={getTextConfig(10, 10, metadata.chromosomesById[chrom.chrom].label)} />-->


                    {/if}
                </Group>
                {/each}
            </Group>

            <Group config={{ x: 40, y: 10, width: 1000, height: 100 }} >

                <Text config={getTextConfig(40, 15, selectedOption.label+' in windows of 500 kbp', 16)} />

                <Group config={{ x: 1040, y: 0, width: 1000, height: 100 }} >
                        {#each colorscalerLegend as color, i}
                        <Rect config={{x: i*2, y: 0, width: 2, height: 15, fill: color}} />
                        {/each}
                        <Rect config={{ x: 0.5, y: 0.5, width: 200, height: 15, stroke: 'black', strokeWidth: 1 }} />

                        <Line config={{points: [0.5, 16, 0.5, 24 ], strokeWidth: 1, stroke: 'black'}} />
                        <Line config={{points: [200.5, 16, 200.5, 24 ], strokeWidth: 1, stroke: 'black'}} />
                        <Text config={getTextConfig(-21.5, 26, roundedFloat(minAll), 12, {width: 50, align: 'center'})} />
                        <Text config={getTextConfig(165.5, 26, roundedFloat(maxAll)+' ['+selectedOption.unit+']', 12, {width: 100, align: 'center'})} />
                        {#if selectedOption.id === 'tajima_d'}
                        <Line config={{points: [100.5, 16, 100.5, 24 ], strokeWidth: 1, stroke: 'black'}} />
                        <Text config={getTextConfig(75, 26, roundedFloat(0.0), 12, {width: 50, align: 'center'})} />
                        {/if}
                </Group>

                <Group config={{ x: 750, y: 5, width: 200, height: 100 }}>
                    <Line config={getCentromereSymbolConfig()} />
                    <Text config={getTextConfig(17, -3, ' position of centromere', 12, {width: 200, align: 'left'})} />
                </Group>

            </Group>

        </Layer>
        {/if}

        <Layer bind:handle={genomePosLabelLayer} config={{ x: 0, y: 0, width: 1600, height: stageHeight }} >
            <Label bind:handle={genomePosLabel} config={{ x: 10.5, y: 10.5, visible: false }}>
                <Tag config={{fill: 'white', stroke: 'black', strokeWidth: 0.5}} />
                <Text bind:handle={genomePosText} config={{text: 'hello', padding: 5}} />
            </Label>
        </Layer>

    </Stage>
    </div>
    <!--{_x}, {y}-->
</div>
    
<style>

table.info {
    padding: 5px;
}

td.title {
    padding-right: 20px;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    font-weight: bold;
}
</style>