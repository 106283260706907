<script>

export let values;
export let barHeight;
export let colorscaler;
export let label;
export let level;

import { afterUpdate } from 'svelte';

import { Stage, Layer, Group, Rect, Line, Circle, Text } from 'svelte-konva';

function getConfigOfHeatmapStripe(i, value) {
    return {
        x: i,
        y: 0,
        width: 1,
        height: barHeight,
        fill: colorscaler(value).hex('rgb')
    }
}

let rect;

afterUpdate(() => {
    rect.moveToTop();
});

</script>


{#each values as value, i}
<Rect config={getConfigOfHeatmapStripe(i, value)} />
{/each}

<Rect on:mousemove on:mouseenter on:mouseleave bind:handle={rect} config={{'_level': level, x: 0-0.5, y: 0+0.5, width: values.length+1, height: barHeight, stroke: 'black', strokeWidth: 1 }} />


<Text config={{ x: values.length+10, y: barHeight*0.30, text: label, fontSize: 11}} />


<style>

</style>