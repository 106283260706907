<script>
import { getContext } from 'svelte';

const context = getContext('app');
let { controller } = context.app();

import getStores from '@/utils/store';
const { settings, windowedStatisticsConfiguration } = getStores();

let heatmapLabels = {
    'variant_density': 'Variants',
    'rare_variant_density': 'Rare Variants (MAF < 0.01)',
    'missing_calls_density': 'Missing Calls',
    'heterozygous_calls_density': 'Heterozygous Calls',
    'gene_density': 'Genes',
    'ld': 'LD (r²)',
    'pi': 'Nucleotide Diversity π',
    'tajima_d': 'Tajima`s D',
    //'watterson_theta': 'Watterson estimator θ',
}

let colorScales = {
    sequential: {
        min: $windowedStatisticsConfiguration.colorScales.sequential.min,
        max: $windowedStatisticsConfiguration.colorScales.sequential.max
    },
    diverging: {
        min: $windowedStatisticsConfiguration.colorScales.diverging.min,
        mid: $windowedStatisticsConfiguration.colorScales.diverging.mid,
        max: $windowedStatisticsConfiguration.colorScales.diverging.max
    }
}

function onChangeColor(evt, store) {
    store = evt.target.value;
    $windowedStatisticsConfiguration['colorScales'] = colorScales;
}

</script>

<div style="min-width: 700px;">
    <div class="divbrowse-modal-dialogue-headline">Settings</div>

    <div style="padding:20px;">

        <div class="section">General</div>

        <div class="option">
            <label class="form-label" for="snp-coloring-selector" style="display: inline-block; width: 300px;">SNP coloring</label>
            <select id="snp-coloring-selector" bind:value={$settings.variantDisplayMode} class="divbrowse-form-control">
                <option value="reference_mismatch">Reference mismatch</option>
                <option value="nucleotides">Nucleotides</option>
            </select>
        </div>

        <div class="option" style="">
            <label class="form-label" for="colorblind-mode" style="display: inline-block; width: 300px; vertical-align: middle;">Colour vision deficiency mode</label>
            <input style="vertical-align: middle; margin-left: 0;" id="colorblind-mode" type=checkbox bind:checked={$settings.statusColorblindMode}>
        </div>

        <div class="section mt">Summary Statistics</div>

        {#each Object.entries(heatmapLabels) as [statId, statLabel]}
        <div class="option" style="">
            <label class="form-label" for="colorblind-mode" style="display: inline-block; width: 300px; vertical-align: middle;">{statLabel}</label>
            <input style="vertical-align: middle; margin-left: 0;" id="colorblind-mode" type=checkbox bind:checked={$windowedStatisticsConfiguration['activeStats'][statId]}>
        </div>
        {/each}


        <div class="section mt">Heatmap colors</div>

        <div class="option" style="">
            <label class="form-label" for="colorblind-mode" style="display: inline-block; width: 300px; vertical-align: middle;">Sequential color scale: maximum</label>
            <input type="color" on:change={(e) => onChangeColor(e, colorScales.sequential.max)} bind:value={colorScales.sequential.max} />
        </div>

        <div class="option" style="">
            <label class="form-label" for="colorblind-mode" style="display: inline-block; width: 300px; vertical-align: middle;">Sequential color scale: minimum</label>
            <input type="color" on:change={(e) => onChangeColor(e, colorScales.sequential.min)} bind:value={colorScales.sequential.min} />
        </div>

        <div class="option" style="">
            <label class="form-label" for="colorblind-mode" style="display: inline-block; width: 300px; vertical-align: middle;">Diverging color scale: minimum</label>
            <input type="color" on:change={(e) => onChangeColor(e, colorScales.diverging.min)} bind:value={colorScales.diverging.min} />
        </div>

        <div class="option" style="">
            <label class="form-label" for="colorblind-mode" style="display: inline-block; width: 300px; vertical-align: middle;">Diverging color scale: mid point / zero</label>
            <input type="color" on:change={(e) => onChangeColor(e, colorScales.diverging.mid)} bind:value={colorScales.diverging.mid} />
        </div>

        <div class="option" style="">
            <label class="form-label" for="colorblind-mode" style="display: inline-block; width: 300px; vertical-align: middle;">Diverging color scale: maximum</label>
            <input type="color" on:change={(e) => onChangeColor(e, colorScales.diverging.max)} bind:value={colorScales.diverging.max} />
        </div>

    </div>

</div>

<style>

.option {
    position: relative;
    padding: 4px 0;
    border-bottom: 1px dotted rgb(170,170,170);
}

.section {
    border-bottom: 1px solid black;
    font-weight: 700;
}

.mt {
    margin-top: 15px;
}

</style>