import { setContext, getContext } from "svelte";
import { writable } from "svelte/store";

import chroma from 'chroma-js';

let appId;

const key = "__stores";


function createGenesBookmarksStore() {

    const localStorageKey = appId+'-genes-bookmarks';

    const store = writable(new Set());

    const bookmarkedGenes = localStorage.getItem(localStorageKey);
    if (bookmarkedGenes !== null) {
        const bookmarkedGenesSet = new Set(JSON.parse(bookmarkedGenes));
        store.set(bookmarkedGenesSet);
    }

    const bookmarkGene = (id) => {
        store.update($ => {
            $.add(id);
            localStorage.setItem(localStorageKey, JSON.stringify(Array.from($)));
            return $;
        });
    }

    const unbookmarkGene = (id) => {
        store.update($ => {
            $.delete(id);
            localStorage.setItem(localStorageKey, JSON.stringify(Array.from($)));
            return $;
        });
    }

    return {
        ...store,
        bookmarkGene,
        unbookmarkGene
    }
}



function createRoiStore() {

    const localStorageKey = appId+'-roi';

    const store = writable(new Map());

    const rois = localStorage.getItem(localStorageKey);

    if (rois !== null) {
        const roisMap = new Map(JSON.parse(rois));
        store.set(roisMap);
    }

    const addRoi = (id, roi) => {
        store.update($ => {
            $.set(id, roi);
            localStorage.setItem(localStorageKey, JSON.stringify(Array.from($.entries())));
            return $;
        });
    }

    const deleteRoi = (id) => {
        store.update($ => {
            $.delete(id);
            localStorage.setItem(localStorageKey, JSON.stringify(Array.from($.entries())));
            return $;
        });
    }

    return {
        ...store,
        addRoi,
        deleteRoi
    }
}


let heatmapLabels = {
    'variant_density': 'Variants',
    'rare_variant_density': 'Rare Variants (MAF < 0.01)',
    'missing_calls_density': 'Missing Calls',
    'heterozygous_calls_density': 'Heterozygous Calls',
    'gene_density': 'Genes',
    'ld': 'LD (r²)',
    'pi': 'Nucleotide Diversity π',
    'tajima_d': 'Tajima`s D',
    //'watterson_theta': 'Watterson estimator θ',
}

let colorRed = chroma('red').darken(1.5).saturate(3).hex();
let colorMaxDivergingInit = chroma('blue');

function createConfigurationChromosomeWindowedStatisticsStore() {
    return  writable({

        colorScales: {
            sequential: {
                max: colorRed,
                min: '#FFFFFF'
            },
            diverging: {
                max: colorMaxDivergingInit,
                mid: '#FFFFFF',
                min: colorRed
            }
        },

        activeStats: {
            'variant_density': true,
            'rare_variant_density': true,
            'missing_calls_density': true,
            'heterozygous_calls_density': true,
            'gene_density': true,
            //'gene_transposon_density': true,
            'read_depth': true,
            'ld': true,
            'pi': true,
            'tajima_d': true,
            //'watterson_theta': 'Watterson estimator θ',
        }
    });
}


function createStores() {

    const stores = {
        settings: writable({
            statusShowMinimap: false,
            zoomX: false,
            zoomY: false,
            statusColorblindMode: false,
            variantDisplayMode: 'reference_mismatch'
        }),
        variantWidth: writable(20),
        groups: writable({}),
        snpPosHighlights: writable({}),
        sortSettings: writable({
            sortmode: 'none',
            sortorder: undefined
        }),
        variantFilterSettings: writable({
            maf: [0.05,0.5],
            missingFreq: [0,0.1],
            heteroFreq: [0,0.1],
            vcfQual: [500,1000]
        }),
        filteredVariantsCoordinates: writable([]),
        geneSearch: writable({
            query: '',
            searchInInterval: false,
            selectedChromosome: undefined,
            startpos: null,
            endpos: null
        }),
        genesBookmarks: createGenesBookmarksStore(),
        variantDensity: writable({}),
        rois: createRoiStore(),
        windowedStatisticsConfiguration: createConfigurationChromosomeWindowedStatisticsStore()
    }

    setContext(key, stores);

    return stores;
}

export default function getStores() {
    
    appId = getContext('app').app().appId;

    const stores = getContext(key);

    if (!stores) {
        return createStores();
    }

    return stores;
}