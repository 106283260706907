<script>
    import { onMount, afterUpdate, tick } from 'svelte';
    
    //import { config, appstate, markerSize, scolorscaler, embeddings } from '../stores/state.js';
    
    import DataFrame from 'dataframe-js';
    import { Stage, Layer, Group, Rect, Line, Circle, Text, Label, Tag } from 'svelte-konva';

    import chroma from 'chroma-js';
    import cloneDeep from 'lodash/cloneDeep';


    import { getContext } from 'svelte';
    const context = getContext('app');
    let { appId, eventbus, controller } = context.app();
    let metadata = controller.getMetadata();


    import getStores from '@/utils/store';
    const roisStore = getStores().rois;
    const storeGeneSearch = getStores().geneSearch;

    const windowedStatisticsConfiguration = getStores().windowedStatisticsConfiguration;


    import ChromosomeHeatmap from '@/components/canvas/ChromosomeHeatmap.svelte';
    import AxisTicks from '@/components/canvas/AxisTicks.svelte';
    
    
    let stageContainer;
    let stage;
    let layer;
    let centromereSymbolGroup;
    let stageHeight = 750;

    let geneIdsIn3rdZoomLevel = [];

    export let chrom; //  = 'chr1H'
    export let roi;

    //let darkred = chroma('red').darken(1.5).saturate(3);

    let colorsSequential = ['white', chroma('red').darken(1.5).saturate(3)];
    let colorsDiverging = [chroma('red').darken(1.5).saturate(3), 'white', 'blue'];

    $: colorsSequential = [
        $windowedStatisticsConfiguration.colorScales.sequential.min,
        $windowedStatisticsConfiguration.colorScales.sequential.max
    ];

    $: colorsDiverging = [
        $windowedStatisticsConfiguration.colorScales.diverging.min, 
        $windowedStatisticsConfiguration.colorScales.diverging.mid, 
        $windowedStatisticsConfiguration.colorScales.diverging.max
    ];

    let windowSizes = {
        '1st': 500000,
        '2nd': 10000,
        '3rd': 200
    };


    let heatmapLabels = {
        'variant_density': 'Variants',
        'rare_variant_density': 'Rare Variants (MAF < 0.01)',
        'missing_calls_density': 'Missing Calls',
        'heterozygous_calls_density': 'Heterozygous Calls',
        'gene_density': 'Genes',
        //'gene_transposon_density': 'Transposon-related Genes',
        'read_depth': 'Read Depth',
        'ld': 'LD (r²)',
        'pi': 'Nucleotide Diversity π',
        'tajima_d': 'Tajima`s D',
        //'watterson_theta': 'Watterson estimator θ',
    }

    let numStats = Object.keys(heatmapLabels).length;

    let activeStats = Object.keys(heatmapLabels);
    //console.log(activeStats);

    windowedStatisticsConfiguration.subscribe(data => {
        let keys = Object.keys(data.activeStats);
        console.log(keys);

        let temp = keys.filter(statId => {
            if (data.activeStats[statId]) {
                return true
            } else {
                return false;
            }
        });
        console.warn(temp);
        activeStats = temp;
        numStats = activeStats.length;
        loadGlobalWindowedStats();
    });

    
    //let values = false;
    let values = {
        '1st': false,
        '2nd': false,
        '3rd': false
    }

    let heatmapLengths = {
        '1st': 0,
        '2nd': 0,
        '3rd': 0
    }
    
    let colorscaler;
    
    let colorscalers = {
        '1st': {},
        '2nd': {},
        '3rd': {},
    }
    
    let barHeight = 20;
    
    let x = {
        '1st': 27,
        '2nd': 100,
        '3rd': 100,
    }

    let xCurrPos = {
        '1st': 123,
        '2nd': 200,
        '3rd': 123,
    }

    let cursorPosLinesVisible = {
        '1st': false,
        '2nd': false,
        '3rd': false
    }

    let draggerGroups = {
        '2nd': undefined,
        '3rd': undefined
    }
    
    let draggers = {
        '1st': undefined,
        '2nd': undefined
    }
    
    let genomePos = {
        '1st': { start: 0, end: 0 },
        '2nd': { start: 0, end: 0 },
        '3rd': { start: 0, end: 0 }
    }

    let tickLabelsFontSize = 10.5;
    
    let ticks = {
        '1st': [],
        '2nd': [],
        '3rd': []
    }

    let cursorPosGroup;

    let genomePosLabelLayer;
    let genomePosLabel;
    let genomePosText;
    

    function createTicks(level, start, end, stepsize = null) {

        if (level === '1st') {
            ticks = {
                '1st': [],
                '2nd': [],
                '3rd': []
            }
        }

        let _values = values[level]['variant_density'];

        let range = end - start;
        let binSize = range / 5;
        let binSizePixels = _values.length / 5;

        ticks[level] = [];

        if (stepsize) {
            binSize = stepsize;
            let intervals = end / stepsize;
            binSizePixels = _values.length / intervals;
 
            for (let i = 0; i <= intervals; i++) {
                ticks[level].push({
                    label: (start + (i * stepsize)).toLocaleString() + ' bp',
                    pos: Math.ceil((binSizePixels * i) - 1)
                });
            }

        } else {
            for (let i = 0; i <= 5; i++) {
                ticks[level].push({
                    label: (start + (i * binSize)).toLocaleString() + ' bp',
                    pos: Math.ceil((binSizePixels * i) - 1)
                });
            }
        }
    }


    async function loadGlobalWindowedStats() {
        const url = controller.config.apiBaseUrl+'/windowed_summary_statistics';
        //const response = await fetch(url);
        const response = await fetch(url, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify({
                subgroup_id: 'all',
            })
        });
        const result = await response.json();
    
        let df = new DataFrame(result[chrom]);

        //let columns = df.listColumns();
        //console.log(columns);

        //df.show();
        //let test = df.drop('watterson_theta');

        df = df.select(...activeStats);
        
        //df.show();
        //test.show();

        values['1st'] = df.toDict();

        //delete values['1st']['pi'];
        //delete values['1st']['watterson_theta'];
        //delete values['1st']['tajima_d'];

        //if (activeStats.includes()) {

        //}

        heatmapLengths['1st'] = values['1st']['variant_density'].length;
        console.log(heatmapLengths);

        for (let statId of df.listColumns()) {
            let _stats = df.stat.stats(statId);
            colorscalers['1st'][statId] = chroma.scale(colorsSequential).domain([_stats.min, _stats.max]).mode('lab');

            if (statId === 'tajima_d') {
                colorscalers['1st'][statId] = chroma.scale(colorsDiverging).domain([_stats.min, 0, _stats.max]).mode('lab');
                //colorscalerLegend = chroma.scale(colorsDiverging).mode('lab').colors(100);
            }
        }
    
        let stats = df.stat.stats('variant_density');
        colorscaler = chroma.scale(colorsSequential).domain([stats.min, stats.max]).mode('lab');

        createTicks('1st', 0, metadata.chromosomesById[chrom].end, 100000000);
        console.log(ticks);
    
        calcGenomeRanges();

        layer.draw();
    }

    
    
    async function loadLocalWindowedStats(level, start, end, windowSize) {

        let url = controller.config.apiBaseUrl+'/windowed_summary_statistics_local';
        let response = await fetch(url, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                chrom: chrom,
                start: start,
                end: end,
                window_size: windowSize
            })
        });

        let result = await response.json();

        //delete result['watterson_theta'];

        let df = new DataFrame(result);

        if (level === '2nd' || level === '3rd') {
            df = df.select(...activeStats);
        }

        df = df.replace(null, 0);

        values[level] = df.toDict();

        for (let statId of df.listColumns()) {
            let _stats = df.stat.stats(statId);
            if (_stats.max == 0) {
                _stats.max = 1;
            }

            colorscalers[level][statId] = chroma.scale(colorsSequential).domain([_stats.min, _stats.max]).mode('lab');

            if (statId === 'tajima_d') {
                colorscalers[level][statId] = chroma.scale(colorsDiverging).domain([_stats.min, 0, _stats.max]).mode('lab');
                //colorscalerLegend = chroma.scale(colorsDiverging).mode('lab').colors(100);
            }
        }
    
        createTicks(level, start, end);

        draggerGroups[level].visible(true);

        /*if (level === '2nd') {
            loadLocalWindowedStats('3rd', genomePos['3rd']['start'], genomePos['3rd']['end'], 200);
        }*/

        if (level === '3rd') {
            listGenesIn3rdZoomLevel();
        }
    }
    

    function onMouseMoveChromosomeHeatmap(evt) {
        let level = evt.detail.target.attrs._level;
        //console.log(evt);
        let cursorPosX = Math.floor(Math.abs(evt.detail.target.getRelativePointerPosition()['x']));
        //console.log(cursorPosX);
        xCurrPos[level] = cursorPosX;

        let cursorGenomePos = genomePos[level]['start'] + (cursorPosX * windowSizes[level]);
        let stageCursorPos = stage.getPointerPosition();

        genomePosLabel.show();
        genomePosText.text(cursorGenomePos.toLocaleString() + ' bp');
        genomePosLabel.position({
            x: stageCursorPos.x + 10,
            y: stageCursorPos.y + 10,
        });
        genomePosLabelLayer.moveToTop();
    }
    
    function onMouseEnterChromosomeHeatmap(evt) {
        let level = evt.detail.target.attrs._level;
        cursorPosLinesVisible[level] = true;
    }
    
    function onMouseLeaveChromosomeHeatmap(evt) {
        let level = evt.detail.target.attrs._level;
        cursorPosLinesVisible[level] = false;
        genomePosLabel.hide();
    }

    function handleDragMove(evt, level) {
        let _x;
        const target = evt.detail.target;
        target.y(0);
    
        _x = target.attrs.x;
        if (_x < 0) {
            target.x(0);
            _x = 0;
        }
    
        let _xMax = values[level]['variant_density'].length-20;
        console.log(_xMax);
        if ( _x > _xMax) {
            target.x(_xMax);
            _x = _xMax;
        }
    
        x[level] = _x;
    }
    
    function handleOnDragEnd() {
        calcGenomeRanges();
    }
    
    function calcGenomeRanges() {
    
        let _x1st = draggers['1st'].x();
        let _x2nd = draggers['2nd'].x();
    
        genomePos['2nd']['start'] = _x1st * windowSizes['1st'];
        genomePos['2nd']['end'] = (_x1st * windowSizes['1st']) + (20 * windowSizes['1st']);
    
        genomePos['3rd']['start'] = genomePos['2nd']['start'] + (_x2nd * windowSizes['2nd']);
        genomePos['3rd']['end'] = genomePos['2nd']['start'] + ( (_x2nd * windowSizes['2nd']) + (20 * windowSizes['2nd']) );
    
        loadLocalWindowedStats('2nd', genomePos['2nd']['start'], genomePos['2nd']['end'], 10000);
        loadLocalWindowedStats('3rd', genomePos['3rd']['start'], genomePos['3rd']['end'], 200);
    }

    function listGenesIn3rdZoomLevel() {
        let df = controller.metadata.gff3._dataframe;

        let _chrom = chrom;

        df = df.filter(row => row.get('seqid') === _chrom);
        
        df = df.filter(row => row.get('start') >= genomePos['3rd']['start']);

        df = df.filter(row => row.get('end') <= genomePos['3rd']['end']);
        df.show();
        geneIdsIn3rdZoomLevel = df.toArray('ID');
        console.log(geneIdsIn3rdZoomLevel);
    }
    
    
    function getTextConfig(x, y, text, size = 15, args = {}) {
        let config = {
            x: x,
            y: y,
            text: text,
            fontSize: size,
            fontFamily: 'Arial',
            fill: 'black'
        }
    
        return {...config, ...args}
    }

    function getCentromereSymbolConfig() {

        let config = {
            x: 0,
            y: 0,
            points: [0, 0, 5, 5, 10, 0],
            closed: true,
            stroke: 'black',
            strokeWidth: 2,
            fill: 'black',
        }
        return config;
    }

    function getCentromereSymbolGroupConfig(chrom) {
        let centromerePosition = Math.ceil(metadata.chromosomesById[chrom].centromere_position / windowSizes['1st']);

        let opacity = 0;
        if (centromerePosition > 0) {
            opacity = 1;
        }

        let config = {
            x: centromerePosition + 0.5 + 20,
            y: 1.5,
            width: 20,
            height: 20,
            opacity: opacity
        }
        return config;
    }
    
    
    function handleOnClickRight() {
        x['1st'] = x['1st'] + 1;
        calcGenomeRanges();
    }
    
    function downloadURI(uri, name) {
        let link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    
    function onClickDownloadImage() {
        let dataURL = stage.toDataURL({ pixelRatio: 3 });
        downloadURI(dataURL, 'chrom_variant_density.png');
    }

    function onChangeTickLabelsFontSize() {
        calcGenomeRanges();
    }

    function cursor(cursorStyle) {
        stageContainer.style.cursor = cursorStyle;
    }

    function onClickSaveAsROI() {
        let newROI = {
            chrom: chrom,
            x: x,
            genomePos: genomePos,
            created: (new Date()).toISOString().split('T')[0]
        }

        let id = chrom+'_'+genomePos['3rd']['start']+'_'+genomePos['3rd']['end'];
        roisStore.addRoi(id, newROI);
    }

    function showOverview() {
        eventbus.emit('showMainComponent', {component: 'ChromosomeOverview'});
    }

    function openGeneSearchForZoomLevel(zoomLevel) {
        $storeGeneSearch.selectedChromosome = chrom;
        $storeGeneSearch.searchInInterval = true;
        $storeGeneSearch.startpos = genomePos[zoomLevel]['start'];
        $storeGeneSearch.endpos = genomePos[zoomLevel]['end'];
        eventbus.emit('modal:open', {
            component: 'GeneSearch'
        });
    }

    onMount(async () => {
        loadGlobalWindowedStats();
    });

    afterUpdate(async () => {
        await tick();
        if (centromereSymbolGroup) {
            centromereSymbolGroup.moveToTop();
        }
    });

    eventbus.on('data:display:changed', _data => {
        //chrom = controller.chromosome;
        //loadGlobalWindowedStats();
    });

    /*$: if (activeStats) {

    }*/


    $: if (roi) {
        console.log(roi);
        chrom = roi.chrom;
        x['1st'] = roi.x['1st'];
        x['2nd'] = roi.x['2nd'];
        loadGlobalWindowedStats();
    }

    $: console.log($roisStore);

</script>

<div bind:this={stageContainer} style="position: relative; border:0px solid black; /*height: 580px; overflow-y: scroll;*/">

    <!--<div style="padding: 20px 10px 10px 60px;">-->
    <div style="display: flow-root; width: 1000px; margin: 16px 0 0 80px; border: 0px solid black;">
        <!--<button class="divbrowse-btn divbrowse-btn-light" on:click={handleOnClickRight}>1 to right</button>-->
        <button class="divbrowse-btn divbrowse-btn-light" on:click={showOverview}>Back</button> 
        <button class="divbrowse-btn divbrowse-btn-light ml" on:click={onClickSaveAsROI}>Save as ROI</button> 
        <button class="divbrowse-btn divbrowse-btn-light ml" on:click={onClickDownloadImage}>Download as Figure</button>
        <!--<input bind:value={tickLabelsFontSize} on:change={onChangeTickLabelsFontSize} type="range" min="10" max="16" /> {tickLabelsFontSize}-->
    </div>

    <div style="position: absolute; top: {(numStats*barHeight)+150}px; left: 1250px; z-index: 100; width: 300px; height: 150px;">
        <button class="divbrowse-btn divbrowse-btn-light ml" on:click={() => openGeneSearchForZoomLevel('2nd')}>Show genes in this region</button>
    </div>

    <div style="position: absolute; top: 550px; left: 1250px; z-index: 100; width: 300px; height: 150px;">
        <button class="divbrowse-btn divbrowse-btn-light ml" on:click={() => openGeneSearchForZoomLevel('3rd')}>Show genes in this region</button>
    </div>

    <div style="border: 0px solid black;">
    <Stage bind:handle={stage} config={{ width: 1600, height: stageHeight }}>

        <Layer bind:handle={layer} config={{ x: 0, y: 0, width: 1600, height: stageHeight }}>

            <Rect config={{ x: 0, y: 0, width: 1600, height: stageHeight, fill: 'rgb(255,255,255)' }} />

            <Text config={ getTextConfig(80, 25, 'Densities in genomic windows for chromosome '+metadata.chromosomesById[chrom].label, 16) } />

            <Group config={{ x: 790, y: 15, width: 200, height: 100 }}>
                <Line config={getCentromereSymbolConfig()} />
                <Text config={getTextConfig(17, -3, ' position of centromere', 12, {width: 200, align: 'left'})} />
            </Group>

            <Group config={{ x: 80, y: 80, width: 1600, height: stageHeight }} >

                <Group config={{ x: 0, y: 0, width: 1600, height: stageHeight }} >

                    <Text config={ getTextConfig(-20, numStats*barHeight, '500 kbp windows', 12, {width: numStats*barHeight, align: 'center', rotation: -90.1}) } />

                    <Group config={{ x: 0, y: 0, width: heatmapLengths['1st'] }} >
                        {#each Object.entries(values['1st']) as [statId, _values], i }
                        <Group config={{x: 0, y: i*barHeight}} >
                            <ChromosomeHeatmap 
                                on:mousemove={(evt) => onMouseMoveChromosomeHeatmap(evt)} 
                                on:mouseenter={(evt) => onMouseEnterChromosomeHeatmap(evt)} 
                                on:mouseleave={(evt) => onMouseLeaveChromosomeHeatmap(evt)} 
                                values={_values} 
                                barHeight={barHeight} 
                                colorscaler={colorscalers['1st'][statId]} 
                                label={heatmapLabels[statId]} 
                                level={'1st'}
                            />
                        </Group>
                        {/each}
                    </Group>

                    <Group bind:handle={cursorPosGroup} config={{ x: 0, y: 0, width: 1200 }} >
                        {#each Object.entries(values['1st']) as [statId, _values], i }
                            <Group config={{x: 0, y: i*barHeight}} >
                                {#if i === 0}
                                <AxisTicks ticks={ticks['1st']} {tickLabelsFontSize} showLabels={true} />
                                {:else}
                                <AxisTicks ticks={ticks['1st']} {tickLabelsFontSize} showLabels={false} />
                                {/if}
                            </Group>
                        {/each}
                    </Group>

                    <Group bind:handle={centromereSymbolGroup} config={getCentromereSymbolGroupConfig(chrom)}>
                        <Line config={getCentromereSymbolConfig()} />
                    </Group>

                </Group>

                <Group config={{ x: 0, y: 0, width: 1600, height: stageHeight }} >
                    <Line config={{ points: [xCurrPos['1st']+0.5, 0, xCurrPos['1st']+0.5, numStats*barHeight], stroke: 'black', strokeWidth: 1, closed: false, dash: [3, 3], visible: cursorPosLinesVisible['1st'] }} />
                </Group>
                
                
                <Group bind:handle={draggerGroups['2nd']} config={{ x: 0, y: (numStats*barHeight)+1, width: 1600, height: 30, visible: false }}>
                    <Group>
                        <Rect bind:handle={draggers['1st']} config={{x: x['1st'], y: 0, width: 20, height: 10, fill: 'rgb(40,40,40)', stroke: 'black', cornerRadius: 0, strokeWidth: 0, fillEnabled: true, strokeHitEnabled: false, draggable: true }} 
                        on:dragmove={(evt) => handleDragMove(evt, '1st') }
                        on:dragend={(evt) => handleOnDragEnd() }
                        on:mouseenter={() => { cursor('pointer')}}
                        on:mouseleave={() => { cursor('default')}} />
                        <Rect config={{x: x['1st']+0.5, y: (-1*(Object.entries(values['1st']).length*barHeight))-0.5, width: 19, height: (Object.entries(values['1st']).length*barHeight), stroke: 'black', cornerRadius: 0, strokeWidth: 1, dash: [3,3], fillEnabled: false, strokeHitEnabled: false, draggable: false }} />
                    </Group>
                    {#if values['2nd']}
                    <Group config={{ x: 0, y: 10 }}>
                        <Line config={{ points: [x['1st'] + 20, 0, values['2nd']['variant_density'].length, 30, 0, 30, x['1st'], 0], fill: 'rgb(205,205,205)', stroke: 'black', strokeWidth: 0, closed: true }} />
                    </Group>
                    {/if}
                </Group>
                
    
                <Group config={{ x: 0, y: (numStats*barHeight)+1+40, width: 1600, height: 100 }}>
                    <Text config={ getTextConfig(-20, numStats*barHeight, '10 kbp windows', 12, {width: numStats*barHeight, align: 'center', rotation: -90.1}) } />
                    {#each Object.entries(values['2nd']) as [statId, _values], i }
                    <Group config={{x: 0, y: i*barHeight}} >
                        <ChromosomeHeatmap 
                            on:mousemove={(evt) => onMouseMoveChromosomeHeatmap(evt)} 
                            on:mouseenter={(evt) => onMouseEnterChromosomeHeatmap(evt)} 
                            on:mouseleave={(evt) => onMouseLeaveChromosomeHeatmap(evt)} 
                            values={_values} 
                            barHeight={barHeight} 
                            colorscaler={colorscalers['2nd'][statId]} 
                            label={heatmapLabels[statId]} 
                            level={'2nd'} 
                        />
                        {#if statId == 'variant_density'}
                        <AxisTicks ticks={ticks['2nd']} {tickLabelsFontSize} showLabels={true} />
                        {:else}
                        <AxisTicks ticks={ticks['2nd']} {tickLabelsFontSize} showLabels={false} />
                        {/if}
                    </Group>
                    {/each}
                </Group>

                <Group config={{ x: 0, y: (numStats*barHeight)+1+40, width: 1600, height: stageHeight }} >
                    <Line config={{ points: [xCurrPos['2nd']+0.5, 0, xCurrPos['2nd']+0.5, numStats*barHeight], stroke: 'black', strokeWidth: 1, closed: false, dash: [3, 3], visible: cursorPosLinesVisible['2nd'] }} />
                </Group>

                
                <Group bind:handle={draggerGroups['3rd']} config={{ x: 0, y: (numStats*2*barHeight)+40+2, width: 1600, height: 30, visible: false }}>
                    <Group>
                        <Rect bind:handle={draggers['2nd']} config={{x: x['2nd'], y: 0, width: 20, height: 10, fill: 'rgb(40,40,40)', stroke: 'black', cornerRadius: 0, strokeWidth: 0, fillEnabled: true, strokeHitEnabled: false, draggable: true }} 
                        on:dragmove={(evt) => handleDragMove(evt, '2nd')} 
                        on:dragend={(evt) => handleOnDragEnd() }
                        on:mouseenter={() => { cursor('pointer')}}
                        on:mouseleave={() => { cursor('default')}} />
                        <Rect config={{x: x['2nd']+0.5, y: (-1*(Object.entries(values['2nd']).length*barHeight))-0.5, width: 19, height: (Object.entries(values['2nd']).length*barHeight), stroke: 'black', cornerRadius: 0, strokeWidth: 1, dash: [3,3], fillEnabled: false, strokeHitEnabled: false, draggable: false }} />
                    </Group>
                    {#if values['3rd']}
                    <Group config={{ x: 0, y: 10 }}>
                        <Line config={{ points: [x['2nd'] + 20, 0, values['3rd']['variant_density'].length, 30, 0, 30, x['2nd'], 0], fill: 'rgb(205,205,205)', stroke: 'black', strokeWidth: 0, closed: true }} />
                    </Group>
                    {/if}
                </Group>

                <Group config={{ x: 0, y: (numStats*2*barHeight)+40+40+2, width: 1600, height: 100 }}>
                    <Text config={ getTextConfig(-20, numStats*barHeight, '200 bp windows', 12, {width: numStats*barHeight, align: 'center', rotation: -90.1}) } />
                    {#each Object.entries(values['3rd']) as [statId, _values], i }
                    <Group config={{x: 0, y: i*barHeight}} >
                        <ChromosomeHeatmap 
                            on:mousemove={(evt) => onMouseMoveChromosomeHeatmap(evt)} 
                            on:mouseenter={(evt) => onMouseEnterChromosomeHeatmap(evt)} 
                            on:mouseleave={(evt) => onMouseLeaveChromosomeHeatmap(evt)} 
                            values={_values} 
                            barHeight={barHeight} 
                            colorscaler={colorscalers['3rd'][statId]} 
                            label={heatmapLabels[statId]} 
                            level={'3rd'} 
                        />
                        {#if statId == 'variant_density'}
                        <AxisTicks ticks={ticks['3rd']} {tickLabelsFontSize} showLabels={true} />
                        {:else}
                        <AxisTicks ticks={ticks['3rd']} {tickLabelsFontSize} showLabels={false} />
                        {/if}
                    </Group>
                    {/each}
                </Group>

                <Group config={{ x: 0, y: (numStats*2*barHeight)+40+40+2, width: 1600, height: stageHeight }} >
                    <Line config={{ points: [xCurrPos['3rd']+0.5, 0, xCurrPos['3rd']+0.5, Object.keys(values['3rd']).length*barHeight], stroke: 'black', strokeWidth: 1, closed: false, dash: [3, 3], visible: cursorPosLinesVisible['3rd'] }} />
                </Group>

            </Group>

        </Layer>

        <Layer bind:handle={genomePosLabelLayer} config={{ x: 0, y: 0, width: 1600, height: stageHeight }} >
            <Label bind:handle={genomePosLabel} config={{ x: 10.5, y: 10.5, visible: false }}>
                <Tag config={{fill: 'white', stroke: 'black', strokeWidth: 0.5}} />
                <Text bind:handle={genomePosText} config={{text: 'hello', padding: 5}} />
            </Label>
        </Layer>

    </Stage>
    </div>
    <!--
    1st: {genomePos['1st']['start'].toLocaleString()} - {genomePos['1st']['end'].toLocaleString()}<br>
    2nd: {genomePos['2nd']['start'].toLocaleString()} - {genomePos['2nd']['end'].toLocaleString()}<br>
    3rd: {genomePos['3rd']['start'].toLocaleString()} - {genomePos['3rd']['end'].toLocaleString()}<br>
    -->


</div>
    
<style>
.ml {
    margin-left: 10px;
}
</style>