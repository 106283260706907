<script>
export let mainComponent;

import { onMount, getContext, afterUpdate } from 'svelte';
const context = getContext('app');
let { appId, eventbus, controller } = context.app();

import getStores from '@/utils/store';
const { settings, variantWidth, groups } = getStores();


let selectedChromosome;
let position;
let chromosomes = [];
let statusBlastButton = false;


let featureOverviewActivated = false;
let btnDisabledByActiveMainComponent = featureOverviewActivated;

eventbus.on('metadata:loaded', metadata => {
    chromosomes = metadata.chromosomes;
    statusBlastButton = metadata.features.blast;
});

function handleGoToPosition(position) {
    if (position && position > 0) {
        controller.goToPosition(position);
    }
}

function handleChangeChromosome(chromosome) {
    controller.setChromosome(chromosome);
}

function handleVariantWidthChange(event) {
    let width = parseInt(event.target.value);
    if (width > 0 && width <= 20) {
        variantWidth.set(width);
        controller.setSnpWidth(width);
    }
}




let btnDisabledState = {
    'backward': true,
    'forward': true,
}

function handleBackward(steps) {
    btnDisabledState['backward'] = true;
    controller.goBackward(steps);
}

function handleForward(steps) {
    btnDisabledState['forward'] = true;
    controller.goForward(steps);
}

let showLoadingAnimation = false;
eventbus.on('loading:animation', msg => {
    showLoadingAnimation = msg.status;
});

function handleClickZoom(which) {
    $settings[which] = !$settings[which];
    if (which === 'zoomX') {
        if ($settings[which]) {
            $variantWidth = 2;
            controller.setSnpWidth(2);
        } else {
            $variantWidth = 20;
            controller.setSnpWidth(20);
        }
    }
    if (which === 'zoomY') {
        $settings.statusShowMinimap = !$settings.statusShowMinimap;
    }
}

let data = false;
let dataGenesLoaded = false;

eventbus.on('data:display:changed', _data => {
    data = _data;

    btnDisabledState['backward'] = false;
    btnDisabledState['forward'] = false;

    if (data.coordinate_first <= controller.metadata.chromosomesById[controller.chromosome].start) {
        btnDisabledState['backward'] = true;
    }

    if (data.coordinate_last >= controller.metadata.chromosomesById[controller.chromosome].end) {
        btnDisabledState['forward'] = true;
    }

    selectedChromosome = controller.chromosome;
});

eventbus.on('data:genes:loaded', _data => {
    dataGenesLoaded = true;
});


function openModal(component) {
    eventbus.emit('modal:open', {
        component: component
    });
}



function showOverview() {
    btnDisabledByActiveMainComponent = true;
    eventbus.emit('showMainComponent', {component: 'ChromosomeOverview'});
}

function showAlleleCalls() {
    btnDisabledByActiveMainComponent = false;
    eventbus.emit('showMainComponent', {component: 'RendererGapless'});
}

let btnDisabledByData = true;
$: btnDisabledByData = data !== false ? false : true;

eventbus.on('showMainComponent', (payload) => {
    if (payload.component === 'ChromosomeSummaryDetails') {
        btnDisabledByActiveMainComponent = true;
    }

    if (payload.component === 'RendererGapless') {
        btnDisabledByActiveMainComponent = false;
    }
});

</script>



<nav class="navigation clearfix" style="position: relative;">

    <div class="navigation-row" style="display: flow-root;">

        <div>
            <label class="form-label" for="chromosome-selector">Chromosome: </label>
            <select class="divbrowse-form-control" bind:value={selectedChromosome} on:change|preventDefault="{handleChangeChromosome(selectedChromosome)}">
                {#each chromosomes as chromosome}
                <option value="{chromosome.id}">{chromosome.label}</option>
                {/each}
            </select>
        </div>
        

        <div style="margin-left:30px;">
            <!--<label class="form-label" for="position-input">Position: </label>-->
            <input bind:value={position} type="number" id="position-input" class="divbrowse-form-control" placeholder="Position">
            <button on:click|preventDefault={() => handleGoToPosition(position)} type="button" class="divbrowse-btn divbrowse-btn-light">Go</button>
        </div>

        <div style="float:left; margin-left:30px;">
            <button on:click|preventDefault={ () => handleBackward(20) } disabled="{btnDisabledState['backward'] | btnDisabledByActiveMainComponent}" type="button" class="divbrowse-btn divbrowse-btn-light"><span style="font-size:20px;">&#8678;&#8678;</span></button>
            <button on:click|preventDefault={ () => handleBackward(10) } disabled="{btnDisabledState['backward'] | btnDisabledByActiveMainComponent}" type="button" class="divbrowse-btn divbrowse-btn-light" style="margin-left:2px;"><span style="font-size:20px;">&#8678;</span></button>
            <button on:click|preventDefault={ () => handleForward(10) } disabled="{btnDisabledState['forward'] | btnDisabledByActiveMainComponent}" type="button" class="divbrowse-btn divbrowse-btn-light" style=""><span style="font-size:20px;">&#8680;</span></button>
            <button on:click|preventDefault={ () => handleForward(20) } disabled="{btnDisabledState['forward'] | btnDisabledByActiveMainComponent}" type="button" class="divbrowse-btn divbrowse-btn-light" style="margin-left:2px;"><span style="font-size:20px;">&#8680;&#8680;</span></button>
        </div>



        <div style="margin-left: 30px; margin-top: 0px;">
            <button class="divbrowse-btn divbrowse-btn-light" style="position: relative; padding-left: 26px;" on:click={() => handleClickZoom('zoomX')} disabled={btnDisabledByActiveMainComponent}>
                <input style="position: absolute; top: 4px; left: 4px; " id="minimap-mode-xaxis" type="checkbox" bind:checked={$settings.zoomX}>
                Zoom X-axis
            </button>
        </div>

        <div style="margin-left: 7px; margin-top: 0px;">
            <button class="divbrowse-btn divbrowse-btn-light" style="position: relative; padding-left: 26px;" on:click={() => handleClickZoom('zoomY')} disabled={btnDisabledByActiveMainComponent}>
                <input style="position: absolute; top: 4px; left: 4px; " id="minimap-mode-xaxis" type="checkbox" bind:checked={$settings.statusShowMinimap}>
                Zoom Y-axis
            </button>
        </div>

    </div>

    <div class="navigation-row" style:padding-left={featureOverviewActivated ? '220px' : '0px'} style="position: relative; /*padding-left: 220px;*/ margin-top:12px; display: flow-root;">

        <!--
        <div id="tab-overview" class="tab tab-inactive" style="">
            <a href="#">Overview</a>
        </div>

        <div id="tab-allele-calls" class="tab" style="">
            Allele Calls
        </div>
        -->

        {#if featureOverviewActivated}
        <button 
            id="tab-overview" 
            class="tab" 
            class:tab-inactive={ ['ChromosomeOverview', 'ChromosomeSummaryDetails'].includes(mainComponent) === false } 
            on:click|preventDefault={() => showOverview()} 
        >Overview</button>
        <button 
            id="tab-allele-calls" 
            class="tab tab-inactive" 
            class:tab-inactive={ mainComponent !== 'RendererGapless' } 
            on:click|preventDefault={() => showAlleleCalls()} 
        >Allele Calls</button>
        {/if}

        <!--
        <div style="float:left;">
            <button on:click|preventDefault={() => showOverview()} type="button" class="divbrowse-btn divbrowse-btn-light">Overview</button>
        </div>
        -->

        <!--
        <div style="float:left; margin-left:10px;">
            <button on:click|preventDefault={() => showOverview()} type="button" class="divbrowse-btn divbrowse-btn-light">Allele Calls</button>
        </div>
        -->

        <div style="float:left; margin-left:0px;">
            <button on:click|preventDefault={() => openModal('GeneSearch')} disabled={dataGenesLoaded !== false ? false : true} type="button" class="divbrowse-btn divbrowse-btn-light">Genes</button>
        </div>

        {#if statusBlastButton === true}
        <div style="float:left; margin-left:10px;">
            <button on:click|preventDefault={() => openModal('Blast')} disabled={btnDisabledByData} type="button" class="divbrowse-btn divbrowse-btn-light" style="font-size:80%;">BLAST</button>
        </div>
        {/if}

        <div style="float:left; margin-left:30px;">
            <button on:click|preventDefault={() => openModal('VariantFilter')} disabled={btnDisabledByData | btnDisabledByActiveMainComponent} type="button" class="divbrowse-btn divbrowse-btn-light">Filter Variants</button>
        </div>

        <div style="float:left; margin-left:10px;">
            <button on:click|preventDefault={() => openModal('SortSamples')} disabled={btnDisabledByData | btnDisabledByActiveMainComponent} type="button" class="divbrowse-btn divbrowse-btn-light">Sort Samples</button>
        </div>

        <div style="float:left; margin-left:10px;">
            <button on:click|preventDefault={() => openModal('DataAnalysisAndExport')} disabled={btnDisabledByData | btnDisabledByActiveMainComponent} type="button" class="divbrowse-btn divbrowse-btn-light">Data Analysis / Export</button>
        </div>

        <div style="float:left; margin-left: 30px;">
            <button on:click|preventDefault={() => openModal('DataSummary')} type="button" class="divbrowse-btn divbrowse-btn-light">Data Summary</button>
        </div>

        <div style="float:left; margin-left:10px;">
            <button on:click|preventDefault={() => openModal('Settings')} type="button" class="divbrowse-btn divbrowse-btn-light">Settings</button>
        </div>

        <!--
        <div style="float:left; margin-left:10px;">
            <button on:click|preventDefault={() => openModal('RegionsOfInterest')} type="button" class="divbrowse-btn divbrowse-btn-light">ROIs</button>
        </div>
        -->

    </div>

</nav>

<style>
* {
  box-sizing: border-box;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.navigation label {
    font-size: 14px;
    line-height: 0.8 !important;
}

.navigation input {
    font-size: 14px;
    line-height: 0.8 !important;
}

.navigation select {
    font-size: 14px;
    line-height: 0.8 !important;
}

.navigation button {
    font-size: 13px;
    line-height: 0.8 !important;
}



.navigation {
    margin-bottom: 10px;
    /*background:rgb(190,190,190);
    padding: 10px 15px;
    border: 1px solid rgb(120, 120, 120);*/
}

.navigation-row {
    width: 100%;
    border: 0px solid black;
}

.navigation-row > div {
    float: left;
    border: 0px solid black;
}

#chromosome-selector {
    width: 150px;
}
#position-input {
    width: 150px;
    margin-right: 0;
}
#width-input {
    width: 60px;
}

.tab {
    display: block;
    position: absolute;
    background: white;
    text-align: center;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 0px;
    padding-top: 6px;
    z-index: 10;
    cursor: pointer;
    font-weight: bold;
}

.tab-inactive {
    background: rgb(240,240,240);
    border-left: 1px solid black;
    border-top: 1px solid rgb(150,150,150);
    border-right: 1px solid rgb(150,150,150);
    border-bottom: 1px solid black;
    font-weight: normal;
    /*box-shadow: 3px 0 3px inset rgba(0,0,0,0.05);*/
    background: linear-gradient(to bottom, rgba(235,235,235,1) 0%, rgba(220,220,220,1) 100%);
    box-shadow: inset 1px 1px 1px 0px #FFF;
    color: rgb(60,60,60);
}

.tab-inactive:hover {
    background: rgb(255,255,255);
    color: black;
}

#tab-overview {
    top: 0px;
    left: 0;
    width: 100px;
    height: 41px;
}

#tab-allele-calls {
    top: 0px;
    left: 99px;
    width: 100px;
    height: 41px;
}
</style>