<script>
export let close;

import cloneDeep from 'lodash/cloneDeep';

import { getContext } from 'svelte';
const context = getContext('app');
let { controller, eventbus } = context.app();

let metadata = controller.getMetadata();

import getStores from '@/utils/store';
const roisStore = getStores().rois;

function onClickDeleteROI(id) {
    roisStore.deleteRoi(id);
}

function onClickViewROIinSummary(id) {
    close();

    let roi = $roisStore.get(id);
    
    eventbus.emit('showMainComponent', {
        component: 'ChromosomeSummaryDetails',
        chrom: roi.chrom,
        roi: cloneDeep(roi)
    });
}

function onClickViewROIinAlleleCalls(id) {
    close();

    let roi = $roisStore.get(id);
    
    eventbus.emit('showMainComponent', {
        component: 'RendererGapless',
        chrom: roi.chrom,
        roi: cloneDeep(roi)
    });
}

$: console.log($roisStore);

</script>

<div style="min-width: 700px;">
    <div class="divbrowse-modal-dialogue-headline">Regions of Interest</div>

    <table>
        <tr class="row">
            <th class="chrom">Chromosome</th>
            <th class="region">Region</th>
            <th class="region">Created</th>
            <th class="centered padding-h">Summary Statistics</th>
            <th class="centered padding-h">Allele Calls</th>
            <th class="centered padding-h"></th>
        </tr>

        {#each [...$roisStore] as [id, roi], i}
        <tr class="row">
            <td class="chrom">{metadata.chromosomesById[roi.chrom].label}</td>
            <td class="region">{(roi.genomePos['3rd']['start']).toLocaleString()} - {(roi.genomePos['3rd']['end']).toLocaleString()}</td>
            <td class="region">{(new Date(roi.created)).toLocaleDateString()}</td>
            <td class="centered"><a href="#" on:click|preventDefault={() => onClickViewROIinSummary(id)}>View</a></td>
            <td class="centered"><a href="#" on:click|preventDefault={() => onClickViewROIinAlleleCalls(id)}>View</a></td>
            <td class="centered"><a href="#" on:click|preventDefault={() => onClickDeleteROI(id)}>Delete</a></td>
        </tr>
        {/each}

    </table>

</div>

<style>

table {
    width: 100%;
    border: 1px solid rgb(150,150,150);
    padding: 4px;
    border-collapse: collapse;
}

tr.row th, td {
    border-bottom: 1px solid rgb(150,150,150);
    padding: 5px;
}

.chrom {
    text-align: left;
    padding-right: 30px;
}

tr.row th.padding-h {
    padding-left: 25px;
    padding-right: 25px;
}

.region {
    text-align: left;
    padding-right: 30px;
}

.centered {
    text-align: center;
}

</style>