<script>
export let ticks;
export let tickLabelsFontSize;
export let showLabels = false;
export let tickHeight = 4;

import { afterUpdate } from 'svelte';

import { Rect, Text } from 'svelte-konva';


function getTickLabelConfig(tick) {
    let config = {
        x: tick.pos-50,
        y: -15,
        width: 100,
        align: 'center',
        text: tick.label,
        fontSize: tickLabelsFontSize,
        fontFamily: 'Arial',
        fill: 'black'
    }

    return config;
}

/*let _rect;

afterUpdate(() => {
    //rect.moveToTop();
});
*/

</script>

{#each ticks as tick, i}
    <Rect config={{ x: tick.pos, y: -4, width: 1, height: tickHeight, fill: 'black' }} />

    {#if showLabels}
    <Text config={getTickLabelConfig(tick)} />
    {/if}
{/each}


<style>

</style>