<script>
import { getContext } from 'svelte';

const context = getContext('app');
let { controller } = context.app();

function sampleDisplayName(sampleId) {
    if (controller.config.sampleDisplayNameTransformer !== undefined && typeof controller.config.sampleDisplayNameTransformer === "function") {
        sampleId = controller.config.sampleDisplayNameTransformer(sampleId);
    }
    return sampleId;
}

</script>

<div>
    <div class="divbrowse-modal-dialogue-headline">Data Summary</div>

    <table style="width: 700px;">
        <tr>
            <td style="width: 200px;">General description:</td>
            <td>{controller.metadata.dataset_descriptions.general_description}</td>
        </tr>
        {#if controller.metadata.dataset_descriptions.vcf_doi != null && controller.metadata.dataset_descriptions.vcf_doi !== ""}
        <tr>
            <td style="width: 200px;">DOI/URL of VCF:</td>
            <td><a href="{controller.metadata.dataset_descriptions.vcf_doi}" target="_blank">{controller.metadata.dataset_descriptions.vcf_doi}</a></td>
        </tr>
        {/if}

        {#if controller.metadata.dataset_descriptions.vcf_reference_genome_doi != null && controller.metadata.dataset_descriptions.vcf_reference_genome_doi !== ""}
        <tr>
            <td style="width: 200px;">DOI/URL of reference genome:</td>
            <td><a href="{controller.metadata.dataset_descriptions.vcf_reference_genome_doi}" target="_blank">{controller.metadata.dataset_descriptions.vcf_reference_genome_doi}</a></td>
        </tr>
        {/if}

        {#if controller.metadata.dataset_descriptions.gff3_doi != null && controller.metadata.dataset_descriptions.gff3_doi !== ""}
        <tr>
            <td style="width: 200px;">DOI/URL of genome annotation:</td>
            <td><a href="{controller.metadata.dataset_descriptions.gff3_doi}" target="_blank">{controller.metadata.dataset_descriptions.gff3_doi}</a></td>
        </tr>
        {/if}

        <tr>
            <td>Number of genotypes:</td>
            <td>{controller.metadata.count_genotypes}</td>
        </tr>
        <tr>
            <td style="width: 200px;">Number of variants:</td>
            <td>{controller.metadata.count_variants}</td>
        </tr>
        <tr>
            <td style="width: 200px;">Number of variants per chromosome:</td>
            <td>
                <div style="height: 130px; overflow-y: scroll; padding-right: 40px;">
                {#each controller.metadata.chromosomes as chrom}
                {chrom.label}: {chrom.number_of_variants}<br/>
                {/each}
                </div>
            </td>
        </tr>
        {#if controller.metadata.gff3.has_gff3 === true}
        <tr>
            <td>Number of genes provided by genome annotation:</td>
            <td>{controller.metadata.gff3.count_genes}</td>
        </tr>
        {/if}
        <tr>
            <td>Genotypes list:</td>
            <td>
                <div style="height: 200px; overflow-y: scroll; padding-right: 40px;">{@html controller.metadata.samples.map(x => sampleDisplayName(x) ).join('<br />')}</div>
            </td>
        </tr>
    </table>
</div>

<style>

table { 
    border-spacing: 0px;
    border-collapse: collapse;
}

table tr td {
    vertical-align: top;
    border: 1px solid rgb(120,120,120);
    border-collapse: collapse;
    padding: 5px;
    margin: 0px;
}

</style>